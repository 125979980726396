// eslint-disable-next-line @typescript-eslint/camelcase
const LOCALSTORAGE_KEY = 'persisted-atoms'

export const initializeState = ({ set }: { set: (key: { key: string }, state: any) => void }) => {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser && localStorage) {
    const localStorageString = localStorage.getItem(LOCALSTORAGE_KEY)

    let state: any = null
    if (!!localStorageString) {
      try {
        if (localStorageString) state = JSON.parse(localStorageString)
      } catch (e) {
        console.log('Error initializing state', e)
        return
      }
    }
    if (state === null) return
    Object.keys(state).forEach((atomKey) => {
      try {
        set({ key: atomKey }, state[atomKey])
      } catch (e) {
        console.log('error initializaing', e)
      }
    })
  }
}
