import qs from 'query-string'
// import Client from '../actions/utils/client'
import { v4 as uuidv4 } from 'uuid'
declare global {
  interface Window {
    [key: string]: any
  }
}

const ELEVAR_LOCALSTORAGE_KEY = '___ELEVAR_GTM_SUITE--lastCollectionPathname'

const validWindow = typeof window !== 'undefined' && !window.location.pathname.includes('giveaways')
const isProduction = process.env.NODE_ENV === 'production'

export const USER_ID = () =>
  validWindow
    ? localStorage.getItem('PF_USER') !== null
      ? localStorage.getItem('PF_USER')
      : localStorage.setItem('PF_USER', uuidv4())
    : ''
export const GA_REGEX = /^_ga_/

export const getCookieMap = () => {
  if (!validWindow) return {}
  if (document.cookie === '') {
    return {}
  }

  return document.cookie.split('; ').reduce((prev, current) => {
    const [name, ...value] = current.split('=')
    prev[name] = value.join('=')
    return prev
  }, {})
}

export const getMarketingCookies = () => {
  const cookieMap = getCookieMap()
  const validMarketingKeys = [
    '_fbp',
    '_fbc',
    '_ga',
    '_gaexp',
    '_gid',
    '__utma',
    'ttclid',
    'crto_mapped_user_id',
    'crto_is_user_optout'
  ]

  // filter cookies to only include marketing cookies
  const filteredCookieMap = validMarketingKeys.reduce((acc, key) => {
    if (cookieMap[key] && cookieMap[key] !== '') {
      acc[key] = cookieMap[key]
    }
    return acc
  }, {})

  // Special case for GA4 cookies
  const matchedGA4Cookies = Object.keys(cookieMap).filter((str) => GA_REGEX.test(str))
  for (const key of matchedGA4Cookies) {
    filteredCookieMap[key] = cookieMap[key]
  }

  return filteredCookieMap
}

/** ***************************************************************************
  getQueryStringParameters - triggers on cart initialization in
  actions/checkout.tsx. Used to assign all query string parameters as
  customAttributes inside the cart.
**************************************************************************** */

export const getQueryStringParameters = () => {
  return validWindow ? qs.parse(window.location.search.substring(1)) : {}
}

const USER_PROPERTIES = {
  user_consent: '',
  visitor_type: 'guest'
}

/** ***************************************************************************
  getGoogleClientId - triggers on cart initialization in actions/checkout.tsx
**************************************************************************** */

export const getGoogleClientId = (): Promise<string> => {
  return new Promise((resolve) => {
    let loaded = false

    window.dataLayer = window.dataLayer || []
    window.gtag =
      window.gtag ||
      function gtag() {
        window.dataLayer.push(arguments)
      }
    window.gtag('js', new Date())

    window.gtag(
      (function () {
        // this function is called after GA library initializes
        loaded = true

        if (!window || !window.ga || !window.ga.getAll) return resolve('')

        const trackers = window.ga.getAll()
        const tracker = trackers[0]
        const clientId: string = tracker && tracker.get('clientId')

        return resolve(clientId)
      })()
    )

    setTimeout(function () {
      if (!loaded) resolve('')
    }, 1500)
  })
}

/** ***************************************************************************
  initializeGaEnhancedEcommerce - triggers on cart initialization in
  actions/checkout.tsx.
**************************************************************************** */

export const initializeGaEnhancedEcommerce = async () => {
  const valid = validWindow && isProduction
  if (!valid) return null
  return await getGoogleClientId()
}

/** ***************************************************************************
  formatProduct - iterates through products and construct the necessary
  product object that gets pushed to the dataLayer
**************************************************************************** */

const formatProduct = (
  productMap: any,
  slug: string,
  list: string,
  imagePath?: string,
  idx?: number,
  variantId?: string,
  price?: number
) => {
  if (!slug || !productMap?.bySlug) return {}

  const product = productMap.bySlug[slug]
  const imageSrc = `https://www.petfinn.com${imagePath}`

  const product_id = variantId
    ? productMap.byVariantId[variantId].productId.decoded
    : product?.single?.productId.decoded

  const productName = variantId
    ? productMap.byVariantId[variantId].title
    : slug.split('-').join(' ').toUpperCase()

  return {
    name: productName,
    id: slug,
    product_id: product_id,
    variant: productName,
    variant_id: variantId || product?.single?.variantId.decoded,
    price: `${price ? price : product?.single?.pricing?.oneTime?.price}`,
    position: idx + 1 || 1,
    list: list || '',
    brand: 'finn',
    category: 'Dog Treats',
    image: imagePath ? imageSrc : '',
    compare_at_price: '0.0'
  }
}

/** ***************************************************************************
  pushToDataLayer - helper function
**************************************************************************** */

const pushToDataLayer = async (dataLayerPayload: any, delay?: any) => {
  try {
    if (validWindow && isProduction) {
      if (delay) {
        setTimeout(() => window.ElevarPushToDataLayer(dataLayerPayload), delay)
      } else {
        window.ElevarPushToDataLayer(dataLayerPayload)
      }
    } else {
      if (delay) {
        setTimeout(() => console.log(dataLayerPayload, { delay }), delay)
      } else {
        console.log(dataLayerPayload)
      }
    }
  } catch (e) {
    console.log(e)
  }
}

/** ***************************************************************************
  setCollectionsPathname & fetchCollectionsPathname - helper functions
**************************************************************************** */

const setCollectionsPathname = () => {
  try {
    localStorage.setItem(ELEVAR_LOCALSTORAGE_KEY, window.location.pathname)
    console.log(`Setting Collections path: ${window.location.pathname}`)
  } catch (e) {
    console.log('localStorage.setItem() error: ', e)
  }
}

const fetchCollectionsPathname = () => {
  try {
    const collectionsPath = localStorage.getItem(ELEVAR_LOCALSTORAGE_KEY) || ''
    console.log(`Fetching Collections path: ${collectionsPath}`)
    return collectionsPath
  } catch (e) {
    console.log('localStorage.getItem() error: ', e)
    return ''
  }
}

/** ***************************************************************************
  trackPageViewEvent
**************************************************************************** */

export const trackPageViewEvent = async (pathname: string, checkout: any, productMap: any) => {
  await trackUser(checkout, productMap) // track user on every Pageview event
}

/** ***************************************************************************
  trackCollectionViewEvent - fires for all visits to Collections page,
  or Singles/Bundles (Homepage, PDPs, etc.)
**************************************************************************** */

export const trackCollectionViewEvent = async (
  productMap: any,
  productSlugs: string[],
  isPdp?: boolean
) => {
  // hiddenProducts are still active on Shopify, but not being shown on the headless site
  const hiddenProducts = ['super-pup-bundle', 'super-pup-holiday', 'senior-pup-bundle']
  const trackedSlugs = productSlugs.filter((slug) => !hiddenProducts.includes(slug))

  const formattedProducts = trackedSlugs.map((slug, idx) =>
    formatProduct(productMap, slug, window.location.pathname, '', idx)
  )

  const dataLayerPayload = {
    event: 'dl_view_item_list',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      impressions: formattedProducts
    }
  }

  // PDPs each have the 4 tins shown at the bottom, but we do not want to overwrite the
  // pathname in this case since that PDP's underlying product is already tagged with one
  if (!isPdp) setCollectionsPathname()

  await pushToDataLayer(dataLayerPayload, 500)
}

/** ***************************************************************************
  trackProductClickEvent - fires whenever link to PDP is clicked
**************************************************************************** */

export const trackProductClickEvent = async (productMap: any, slug: string, isPdp?: boolean) => {
  let collectionsList = fetchCollectionsPathname()

  // Reassigns the store collectionsPathname to be the PDP since we prevented that
  // behavior when landing there to prevent overwriting it initially
  if (isPdp) {
    collectionsList = window.location.pathname
    setCollectionsPathname()
  }

  const clickedProduct = formatProduct(productMap, slug, collectionsList)

  const dataLayerPayload = {
    event: 'dl_select_item',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      click: {
        actionField: { list: collectionsList, action: 'click' },
        products: [clickedProduct]
      }
    }
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackProductViewEvent - fires from each PDP
**************************************************************************** */

export const trackProductViewEvent = async (
  productMap: any,
  slug: string,
  imagePath: string,
  variantId: string
) => {
  const collectionsList = fetchCollectionsPathname()
  const viewedProduct = formatProduct(
    productMap,
    slug,
    collectionsList,
    imagePath,
    undefined,
    variantId
  )

  const dataLayerPayload = {
    event: 'dl_view_item',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: collectionsList, action: 'detail' },
        products: [
          {
            ...viewedProduct,
            inventory: '1'
          }
        ]
      }
    }
  }

  await pushToDataLayer(dataLayerPayload, 500)
}

/** ***************************************************************************
  updateProductViewEvent - fires when radio product selector clicked
**************************************************************************** */

export const updateProductViewEvent = async (
  productMap: any,
  slug: string,
  variantId: string,
  price: number,
  imagePath: string
) => {
  const collectionsList = fetchCollectionsPathname()

  const updatedProduct = formatProduct(
    productMap,
    slug,
    collectionsList,
    imagePath,
    0,
    variantId,
    price
  )

  const dataLayerPayload = {
    event: 'dl_view_item',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: collectionsList },
        products: [
          {
            ...updatedProduct,
            inventory: '1'
          }
        ]
      }
    }
  }

  await pushToDataLayer(dataLayerPayload, 500)
}

/** ***************************************************************************
  trackAddToCartEvent - fires when ATC CTAs are clicked on Homepage,
  COllections, About, PDP, and inside Cart Rows via quantity toggle.
**************************************************************************** */

export const trackAddToCartEvent = async (
  productMap: any,
  slug: string,
  variantId: string,
  price: number,
  frequency?: string
) => {
  const collectionsList = fetchCollectionsPathname()

  const addedProduct = formatProduct(productMap, slug, collectionsList, '', 0, variantId, price)

  const dataLayerPayload = {
    event: 'dl_add_to_cart',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      add: {
        actionField: { list: collectionsList },
        products: [
          {
            ...addedProduct,
            quantity: '1',
            frequency
          }
        ]
      }
    }
  }

  await pushToDataLayer(dataLayerPayload)

  if (window.TriplePixel) {
    window.TriplePixel('AddToCart', { item: variantId, q: 1 })
  }
}

/** ***************************************************************************
  trackRemoveFromCartEvent - fires from Cart Rows via quantity toggle.
**************************************************************************** */

export const trackRemoveFromCartEvent = async (
  productMap: any,
  slug: string,
  variantId: string,
  price: number
) => {
  const collectionsList = fetchCollectionsPathname()

  const removedProduct = formatProduct(productMap, slug, collectionsList, '', 0, variantId, price)

  const dataLayerPayload = {
    event: 'dl_remove_from_cart',
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      remove: {
        actionField: { list: collectionsList },
        products: [
          {
            ...removedProduct,
            quantity: '1'
          }
        ]
      }
    }
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackSwapToSubscriptionEvent
**************************************************************************** */

export const trackSwapToSubscriptionEvent = async (variantId: string) => {
  const dataLayerPayload = {
    event: 'swap-otp-to-subscription',
    variant_id: variantId
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackSwapToOtpEvent
**************************************************************************** */

export const trackSwapToOtpEvent = async (variantId: string) => {
  const dataLayerPayload = {
    event: 'swap-subscription-to-otp',
    variant_id: variantId
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackViewCartEvent fires from (1) actions/checkout.tsx on ATC and (2) in
  NvaBar when opening the cart menu by clicking the cart icon.
**************************************************************************** */

export const trackViewCartEvent = async (productMap: any, lineItems: any, subtotal: string) => {
  const cartItems = convertShopifyLineItemsToElevarProducts(lineItems, productMap)

  const dataLayerPayload = {
    event: 'dl_view_cart',
    cart_total: String(subtotal),
    user_properties: USER_PROPERTIES,
    ecommerce: {
      currencyCode: 'USD',
      actionField: { list: 'Shopping Cart' },
      impressions: cartItems
    }
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  These are used only for tracking consultation events
**************************************************************************** */

export const trackConsultationView = async (screen: string) => {
  // Sends "Virtual Page View" representing the consultation screen
  const page = `/consultation/${screen}`

  const dataLayerPayload = {
    event: 'dl_view_consultation',
    page_location: page
  }

  await pushToDataLayer(dataLayerPayload)
}

export const trackCustomClickEvent = async (name: string) => {
  const dataLayerPayload: { [key: string]: string } = {
    event: 'dl_custom_click_event',
    event_name: name
  }

  await pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  These are used only for tracking user
**************************************************************************** */
export const initialUTMList = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'gclid',
  'fbclid',
  'ttclid',
  'irclickid'
]

const nonGoogleClickId = ['fbclid', 'ttclid', 'irclickid']
const utmTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

export const getLocalStorageUTM = () => {
  return initialUTMList.reduce((acc, utm) => {
    const value = localStorage.getItem(utm)
    if (value) {
      acc[utm] = value
    }
    return acc
  }, {})
}

export const storeUTM = () => {
  const params = getQueryStringParameters()
  // if query string params doesn't have any matching utm tags, then return
  const hasMatchingUTM = initialUTMList.some((utm) => params[utm])
  if (!hasMatchingUTM) return

  // utm tags that are in the query string
  const currentParams = initialUTMList.reduce((acc, utm) => {
    const value = params[utm]
    if (value) {
      acc[utm] = value
    }
    return acc
  }, {})

  // utm tags that are in local storage
  const storedParams = getLocalStorageUTM()

  // if there are no utm tags in local storage, then it is first time visitor
  // then store all utm tags from the query string
  const firstTimeVisitor = Object.keys(storedParams).length === 0
  if (firstTimeVisitor) {
    for (const [key, value] of Object.entries(currentParams)) {
      localStorage.setItem(key, value)
    }
    return
  }

  // if click id other than the gclid is present (fbclid, ttclid, ...) without accompanying utms,
  // save this value without making changes to the existing parameters.
  // if click id other than the gclid is present (fbclid, ttclid)
  // AND new utm values ARE present clear the utm values and gclid, and save the new click id and utms.
  if (nonGoogleClickId.some((utm) => currentParams[utm])) {
    // save new click id
    for (const clickId of nonGoogleClickId) {
      if (currentParams[clickId]) {
        localStorage.setItem(clickId, currentParams[clickId])
      }
    }
    // if there are new utm values, clear the existing utms and gclid
    if (utmTags.some((utm) => currentParams[utm])) {
      for (const tag of utmTags) {
        localStorage.removeItem(tag)
        if (currentParams[tag]) {
          localStorage.setItem(tag, currentParams[tag])
        }
      }
      localStorage.removeItem('gclid')
    }
  }

  // if the user has new utms or a new gclid, clear the existing utms and gclid
  // (but leave any other click ids) and replace them with whatever is present.
  if (utmTags.some((utm) => currentParams[utm]) || currentParams.gclid) {
    for (const tag of utmTags) {
      localStorage.removeItem(tag)
      if (currentParams[tag]) {
        localStorage.setItem(tag, currentParams[tag])
      }
    }
    localStorage.removeItem('gclid')
    if (currentParams['gclid']) {
      localStorage.setItem('gclid', currentParams.gclid)
    }
    return
  }
}

export const trackUser = async (checkout, productMap) => {
  const dataLayerPayload = {
    event: 'dl_user_data',
    cart_total: checkout.subtotalPrice?.amount,
    user_properties: {
      user_consent: '',
      visitor_type: 'guest'
    },
    ecommerce: {
      currencyCode: 'USD',
      cart_contents: {
        products: convertShopifyLineItemsToElevarProducts(checkout.lineItems, productMap)
      }
    }
  }

  await pushToDataLayer(dataLayerPayload)
}

function convertShopifyLineItemsToElevarProducts(lineItems: any, productMap: any) {
  return lineItems.map((item: any, idx: number) => {
    const { title, variant, quantity } = item
    const fullVariantId = variant.id // e.g. "gid://shopify/ProductVariant/39848003141714"
    const variantIdBreakdown = fullVariantId.split('/')
    const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]

    const variantMap = productMap?.byVariantId
    const slug = variantMap ? variantMap[variantId].slug : 'unknown'
    const collectionsList = fetchCollectionsPathname()

    const formattedProduct = formatProduct(
      productMap,
      slug,
      collectionsList,
      '',
      idx,
      variantId,
      item.variant.price.amount
    )

    return {
      ...formattedProduct,
      quantity: String(quantity),
      variant: title.toUpperCase()
    }
  })
}
